var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partner"},[_c('div',{staticClass:"boxContent"},[_c('div',{staticClass:"navbar main-container"},[_c('Navbar',{attrs:{"itemIndex":'5'}})],1)]),_vm._m(0),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"links linkbg1"},[_vm._m(1),_c('section',{staticClass:"partner-section"},[_c('ul',_vm._l((_vm.dataArray1),function(item){return _c('li',{key:item},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)])]),_c('div',{staticClass:"links linkbg2"},[_vm._m(2),_c('section',{staticClass:"partner-section"},[_c('ul',_vm._l((_vm.dataArray2),function(item){return _c('li',{key:item},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)])]),_c('div',{staticClass:"links linkbg2"},[_vm._m(3),_c('section',{staticClass:"partner-section"},[_c('ul',_vm._l((_vm.dataArray3),function(item){return _c('li',{key:item},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)])]),_c('div',{staticClass:"links linkbg3"},[_vm._m(4),_c('section',{staticClass:"partner-section"},[_c('ul',_vm._l((_vm.dataArray6),function(item){return _c('li',{key:item},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)])]),_c('div',{staticClass:"links linkbg4"},[_vm._m(5),_c('section',{staticClass:"partner-section"},[_c('ul',_vm._l((_vm.dataArray4),function(item){return _c('li',{key:item},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)]),_c('section',{staticClass:"partner-section mb63"},[_c('ul',_vm._l((_vm.dataArray5),function(item){return _c('li',{key:item,staticClass:"company"},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])]),_c('div',{staticClass:"boxContentFooter"},[_c('FooterAll')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"barnner-bg bg-pic"},[_c('div',{staticClass:"banner main-container"},[_c('div',{staticClass:"banner-title"},[_vm._v(" 合作伙伴 ")]),_c('div',{staticClass:"see-more"},[_vm._v(" 我们珍视每一个伴我们成长的客户，为每一个客户提供专业且周到的服务 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex"},[_c('img',{attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}}),_vm._v("政府合作伙伴"),_c('img',{staticClass:"rotate180",attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex"},[_c('img',{attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}}),_vm._v("院校合作伙伴"),_c('img',{staticClass:"rotate180",attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex"},[_c('img',{attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}}),_vm._v("银行合作伙伴"),_c('img',{staticClass:"rotate180",attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex"},[_c('img',{attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}}),_vm._v("金融机构合作伙伴"),_c('img',{staticClass:"rotate180",attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title flex"},[_c('img',{attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}}),_vm._v("企业合作伙伴"),_c('img',{staticClass:"rotate180",attrs:{"src":require("@/assets/partner/co_tit.png"),"alt":""}})])
}]

export { render, staticRenderFns }