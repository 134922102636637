<template>
  <div class="partner">
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar main-container">
        <Navbar :itemIndex="'5'" />
      </div>

    </div>
    <!-- banner区域 -->
    <div class="barnner-bg bg-pic">
      <div class="banner main-container">
        <div class="banner-title">
          合作伙伴
        </div>
        <div class="see-more">
          我们珍视每一个伴我们成长的客户，为每一个客户提供专业且周到的服务
        </div>
      </div>
    </div>

    <div class="bg">
      <!--      政府合作伙伴-->
      <div class="links linkbg1">
        <div class="title flex">
          <img src="@/assets/partner/co_tit.png" alt="">政府合作伙伴<img class="rotate180" src="@/assets/partner/co_tit.png" alt="">
        </div>
        <section class="partner-section">
          <ul>
            <li v-for="item in dataArray1" :key="item">
              <img v-lazy="item" alt="">
            </li>
          </ul>
        </section>
      </div>

      <!--      院校合作伙伴-->
      <div class="links linkbg2">
        <div class="title flex">
          <img src="@/assets/partner/co_tit.png" alt="">院校合作伙伴<img class="rotate180" src="@/assets/partner/co_tit.png" alt="">
        </div>
        <section class="partner-section">
          <ul>
            <li v-for="item in dataArray2" :key="item">
              <img v-lazy="item" alt="">
            </li>
          </ul>
        </section>
      </div>
      <!--    银行合作伙伴-->
      <div class="links linkbg2">
        <div class="title flex">
          <img src="@/assets/partner/co_tit.png" alt="">银行合作伙伴<img class="rotate180" src="@/assets/partner/co_tit.png" alt="">
        </div>
        <section class="partner-section">
          <ul>
            <li v-for="item in dataArray3" :key="item">
              <img v-lazy="item" alt="">
            </li>
          </ul>
        </section>
      </div>
      <!--      金融机构合作伙伴-->
      <div class="links linkbg3">
        <div class="title flex">
          <img src="@/assets/partner/co_tit.png" alt="">金融机构合作伙伴<img class="rotate180" src="@/assets/partner/co_tit.png" alt="">
        </div>
        <section class="partner-section">
          <ul>
            <li v-for="item in dataArray6" :key="item">
              <img v-lazy="item" alt="">
            </li>
          </ul>
        </section>
      </div>
      <!--      企业合作伙伴-->
      <div class="links linkbg4">
        <div class="title flex">
          <img src="@/assets/partner/co_tit.png" alt="">企业合作伙伴<img class="rotate180" src="@/assets/partner/co_tit.png" alt="">
        </div>
        <section class="partner-section">
          <ul>
            <li v-for="item in dataArray4" :key="item">
              <img v-lazy="item" alt="">
            </li>
          </ul>
        </section>
        <section class="partner-section mb63">
          <ul>
            <li v-for="item in dataArray5" :key="item" class="company">
              {{ item }}
            </li>
          </ul>
        </section>
      </div>

    </div>



    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    components: {
      Navbar,
      FooterAll,
    },
    data(){
      return{
        dataArray1:[
            require('@/assets/partner/cooperate_zf_01@2x.png'),
            require('@/assets/partner/cooperate_zf_02@2x.png'),
            require('@/assets/partner/cooperate_zf_03@2x.png'),
            require('@/assets/partner/cooperate_zf_04@2x.png'),
            require('@/assets/partner/cooperate_zf_05@2x.png')
        ],
        dataArray2:[
          require('@/assets/partner/cooperate_yx_01@2x.png'),
          require('@/assets/partner/cooperate_yx_02@2x.png'),
        ],
        dataArray3:[
          require('@/assets/partner/cooperate_yh_01@2x.png'),
          require('@/assets/partner/cooperate_yh_02@2x.png'),
          require('@/assets/partner/cooperate_yh_03@2x.png'),
          require('@/assets/partner/cooperate_yh_04@2x.png'),
          require('@/assets/partner/cooperate_yh_05@2x.png'),
          require('@/assets/partner/cooperate_yh_06@2x.png'),
          require('@/assets/partner/cooperate_yh_07@2x.png'),
          require('@/assets/partner/cooperate_yh_08@2x.png'),
        ],
        dataArray4:[
          require('@/assets/partner/cooperate_qy_01@2x.png'),
          require('@/assets/partner/cooperate_qy_02@2x.png'),
          require('@/assets/partner/cooperate_qy_03@2x.png'),
          require('@/assets/partner/cooperate_qy_04@2x.png'),
          require('@/assets/partner/cooperate_qy_05@2x.png'),
          require('@/assets/partner/cooperate_qy_06@2x.png'),
          require('@/assets/partner/cooperate_qy_07@2x.png'),
          require('@/assets/partner/cooperate_qy_08@2x.png'),
          require('@/assets/partner/cooperate_qy_09@2x.png'),
          require('@/assets/partner/cooperate_qy_10@2x.png'),
          require('@/assets/partner/cooperate_qy_11@2x.png'),
          require('@/assets/partner/cooperate_qy_12@2x.png'),
          require('@/assets/partner/cooperate_qy_13@2x.png'),
          require('@/assets/partner/cooperate_qy_14@2x.png'),
          require('@/assets/partner/cooperate_qy_15@2x.png'),
          require('@/assets/partner/cooperate_qy_16@2x.png'),
          require('@/assets/partner/cooperate_qy_17@2x.png'),
        ],
        dataArray6: [
          require('@/assets/partner/cooperate_jr_01@2x.png'),
          require('@/assets/partner/cooperate_jr_02@2x.png'),
          require('@/assets/partner/cooperate_jr_03@2x.png'),
          require('@/assets/partner/cooperate_jr_04@2x.png'),
          require('@/assets/partner/cooperate_jr_05@2x.png'),
          require('@/assets/partner/cooperate_jr_06@2x.png'),
          require('@/assets/partner/cooperate_jr_07@2x.png'),
          require('@/assets/partner/cooperate_jr_08@2x.png'),
        ],
        dataArray5:[
            '青岛顺慧城市科技发展有限公司','芬凯德软件技术（北京）有限公司','科睿柏（重庆）软件开发有限公司',
            '新关注（北京）网络技术有限公司','中华环保联合会','北京市浩天信和律师事务所',
            '泰悦科技（北京）有限公司','联洋国融（北京）科技有限公司','深圳迷河科技有限公司','北京择木栖投资有限公司',
            '合肥万普置业有限公司','国堃（舟山）科技股份有限公司','苏州鸿链信息科技有限公司','河口昆钢矿业开发有限公司',
            '山东政和科技股份有限公司','猪八戒股份有限公司','上海链接者集团有限公司','金银州金融服务外包（北京）有限公司',
            '北京合众鼎成科技有限公司','亿利碳金资产管理有限公司','南通石化产品平台服务有限公司','云南现代物流信息服务有限公司',
            '金联创网络科技有限公司','山东京博控股集团有限公司','普天合电','中电科新型智慧城市研究院有限公司',
            '浙江浙大中控信息技术有限公司','西藏高驰科技信息产业集团有限责任公司','西陇科学股份有限公司'

        ]
      }
    }
  }

</script>
<style lang="scss" scoped>
  @import "./parter.scss";
</style>
<style scoped>
.bg-pic{
  background: url('../../assets/partner/co_banner.png')
  no-repeat ;
  background-size: 1920px 320px;
  background-position: 50%;
  min-width: 1180px;
}
</style>
